import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import web5 from "../img/retail1.jpg";
import "../style/styles.css";
import { Helmet } from "react-helmet";

const Retails = () => {
  const alttext = "Photo";

  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          EBS Software Solution - Touch Enabled Retail Outlet Software, West Bengal, India
        </title>
        <meta
          name="description"
          content="Retail Management Software POS Software (Point of Sale) Retail Software Solutions Touchscreen Retail Software Retail Inventory Management Software Retail Accounting Software Point of Sale System Inventory Control Software
Retail Software for Small Businesses
Android POS System for Retail
Retail ERP Software
Cloud-based Retail Software
Sales and Inventory Software Best touchscreen retail management software
Retail POS software for inventory tracking
Affordable retail POS system for small businesses
Cloud-based retail software for inventory and accounting
Android-based POS software for retail outlets
Retail POS with real-time inventory management
Multi-store retail POS software
Retail software with integrated payment system
Customizable retail POS solutions
POS software with inventory and order management Efficient inventory management software for retail
Real-time sales tracking software for retailers
Easy-to-use retail POS system
Advanced retail software with integrated analytics
Smart retail software with automated alerts
Seamless integration for retail POS and inventory
Retail software with cloud synchronization"
        />
      </Helmet>

      {/* Main Container */}
      <Container>
        {/* Hero Image */}
        <Row>
          <Col sm={12}>
            <img
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                width: "90%",
              }}
              className="responsive-image"
              src={web5}
              alt={alttext}
            />
          </Col>
        </Row>

        {/* Page Title */}
        <Row>
          <Col lg={12}>
            <br />
            <h2 style={{ fontWeight: "bold", textAlign: "center" }}>SaleSync</h2>
            <p
              style={{
                fontWeight: "bold",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              GST/Touch Enabled Retail Management System
            </p>
            <br />
          </Col>
        </Row>

        {/* SalesSync Description */}
        <Row>
          <Col lg={12}>
            <p style={{ textAlign: "justify", fontSize: "16px",fontWeight:"bold", lineHeight: "1.6" }}>
              <strong>SalesSync: Advanced Retail Billing and Inventory Management System</strong>
              <br /><br />
              SalesSync is a comprehensive, cutting-edge solution designed to streamline retail operations, optimize inventory management, and provide insightful business analytics. Offering a complete suite of features, SalesSync enables businesses to efficiently handle billing, inventory tracking, and reporting while maintaining high levels of security and control. Key features include:
            </p>

            {/* Features List */}
            <div style={{ textAlign: "justify", fontSize: "16px", lineHeight: "1.6" }}>
              <ol>
                <li>
                  <strong>Retail Billing:</strong> A user-friendly and efficient retail billing system that supports multiple payment methods, generates detailed invoices, and automatically updates inventory in real time.
                </li>
                <li>
                  <strong>Credit GST Billing:</strong> Fully compliant with GST regulations, SalesSync provides seamless credit-based billing, ensuring accurate tax calculations and transparent financial management.
                </li>
                <li>
                  <strong>Purchase Billing (Inward Goods):</strong> Simplified billing for inward goods received from suppliers, with automatic inventory updates, ensuring timely and accurate tracking of stock levels.
                </li>
                <li>
                  <strong>Stock Adjustment:</strong> Real-time stock adjustment tools to reconcile physical inventory with system data, ensuring consistency and accuracy in inventory records.
                </li>
                <li>
                  <strong>Physical Stock Taking:</strong> Tools for performing accurate physical stock counts, enabling businesses to reconcile actual inventory with system records and maintain inventory integrity.
                </li>
                <li>
                  <strong>Minimum Stock Level Alerts:</strong> Automated alerts for when stock levels fall below predefined minimum thresholds, ensuring timely replenishment and preventing stockouts.
                </li>
                <li>
                  <strong>Comprehensive Inventory Management:</strong> A complete and robust inventory management system, including:
                  <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
                    <li>o <strong>Stock Summary:</strong> Real-time visibility into stock levels across multiple locations and categories for efficient inventory management.</li>
                    <li>o <strong>Closing Stock Reporting:</strong> Accurate tracking of closing stock values for financial reporting and strategic planning.</li>
                    <li>o <strong>High-Value Item Tracking:</strong> Identification and tracking of high-value items to enhance risk management and ensure proper control over critical assets.</li>
                    <li>o <strong>Dead Stock Management:</strong> Automated identification and reporting of obsolete or slow-moving inventory, helping businesses take corrective actions to reduce excess stock.</li>
                    <li>o <strong>Product Ledger:</strong> A detailed ledger capturing all product movements, including purchases, sales, adjustments, and transfers, providing a complete transaction history for each item.</li>
                  </ul>
                </li>
                <li>
                  <strong>Management Information System (MIS) Reports:</strong> In-depth reporting features designed to provide valuable insights into sales and inventory performance:
                  <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
                    <li>o <strong>Product Sales Details:</strong> Daily and monthly sales reports, offering a granular view of product performance, sales trends, and profitability.</li>
                    <li>o <strong>Graphical Sales and Purchase Analysis:</strong> Visual reports on sales and purchase data over the past three months, helping businesses identify trends, optimize purchasing strategies, and improve decision-making.</li>
                    <li>o <strong>Back Office Dashboard:</strong> A customizable dashboard providing real-time data, key performance indicators (KPIs), and operational alerts, enabling back-office personnel to efficiently manage daily operations.</li>
                    <li>o <strong>Minimum Stock Level Tracking:</strong> Visual tracking of inventory levels and alerts for items approaching their minimum stock thresholds, allowing businesses to prevent stockouts and streamline procurement.</li>
                  </ul>
                </li>
                <li>
                  <strong>Entry-Level Security Features:</strong> SalesSync offers robust security protocols to ensure data integrity and confidentiality, including:
                  <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
                    <li>o <strong>User-to-User Security:</strong> Role-based access controls, ensuring that users have appropriate permissions based on their roles and responsibilities within the organization, safeguarding sensitive data and operations.</li>
                  </ul>
                </li>
              </ol>
            </div>

            {/* Conclusion */}
            <p style={{ textAlign: "justify", fontSize: "16px", lineHeight: "1.6" }}>
              With SalesSync, businesses benefit from a comprehensive, secure, and easy-to-use system for retail billing, inventory management, and detailed reporting. The system enhances operational efficiency, ensures inventory accuracy, and provides valuable insights to drive business success.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Retails;